<template>
  <component
    :is="to ? nuxtLink : 'div'"
    :to="to"
    :class="[
      $style['card'],
      {[$style['card--contain']]: contain },
      {[$style['card--reveal']]: reveal },
      {[$style[`card--${background}`]]: background }
    ]">
    <div v-if="image" :class="$style['card__image']">
      <NuxtImg
        format="webp"
        :src="image"
        quality="50"
        width="400"
        loading="lazy"
        :alt="alt" />
    </div>
    <div v-if="!image && !!$slots.image" :class="$style['card__image']">
      <slot name="image" />
    </div>
    <div :class="$style['card__content']">
      <div v-if="title" :class="$style['card__title']">
        {{ title }}
      </div>
      <slot />
    </div>
    <div v-if="!!$slots.actions" :class="$style['card__actions']">
      <slot name="actions" />
    </div>
  </component>
</template>
<script lang="ts" setup>
const nuxtLink = resolveComponent('nuxt-link')
interface Props {
  image?: string | null
  to?: object | string | null,
  alt?: string | undefined,
  title?: string | undefined,
  reveal?: boolean,
  contain?: boolean,
  background?: 'dark' | 'light' | null
}
withDefaults(defineProps<Props>(), {
  image: null,
  to: null,
  contain: false,
  background: null,
  alt: undefined,
  title: null
})
</script>
<style module>
.card {
  /* aspect-ratio: 10/16; */
  background-color: var(--secondary-surface);
  border-radius: var(--border-radius);
  color: var(--secondary-on-surface);
  display: flex;
  height: auto;
  flex-direction: column;
  box-shadow: var(--box-shadow-elevation-1);
  text-decoration: none;
  transition: all var(--duration-micro-fast) var(--easing-transition);
  overflow: hidden;
  position: relative;
}

.card__title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-medium);
  font-family: var(--font-family-heading);
  margin-bottom: 0.25rem;
}

.card__image {
  background-color: var(--background-subtle);
  display: flex;
  width: 100%;
  transition: transform var(--duration-micro-normal) var(--easing-transition);
  transform-origin: 100% 100%;
}

.card__image > * {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
}

.card__content {
  padding: var(--spacing-l);
  height: auto;
  flex: 1;
  transition: transform var(--duration-micro-normal) var(--easing-transition);
  transform-origin: 100% 100%;
}

.card__actions {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing);
  padding: var(--spacing);
  transform: translateY(100%);
  position: absolute;
  bottom: 0;
  transition: transform var(--duration-micro-normal) var(--easing-transition);
  width: 100%;
}

.card--light .card__image {
  background-color: var(--light-color-80);
}

.card--dark .card__image {
  background-color: var(--dark-color-30);
}

.card--contain .card__image {
  padding: var(--spacing);
  width: 100%;
}

.card--contain .card__image > * {
  object-fit: contain;
}

.card--reveal .card__image,
.card--reveal .card__content,
.card--reveal .card__actions,
.card:not([href]) .card__image,
.card:not([href]) .card__content,
.card:not([href]) .card__actions {
  transform: none;
  position: static;
}

.card[href]:not(.card--reveal):hover {
  color: var(--color-primary);
  box-shadow: var(--box-shadow-elevation-3);
}

.card[href]:not(.card--reveal):focus-visible {
  box-shadow: 0 0 0 2px var(--secondary-on-surface);
}

.card[href]:not(.card--reveal):hover .card__actions {
  transform: translateY(0);
}

.card[href]:not(.card--reveal):hover .card__image,
.card[href]:not(.card--reveal):hover .card__content {
  transform: translateY(calc(var(--spacing-xxxxl) * -1));
}

</style>
